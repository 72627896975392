import React, { Component } from 'react'
import CallToActionBanner from '../../../components/CallToActionBanner'
import Footer from '../../../components/Footer'
import DashboardHeader from '../../../components/DashboardHeader'
import ChangePasswordForm from '../../../components/ChangePasswordForm';

const ChangePassword = () => {
    return (
        <>
            <DashboardHeader bgColor={true} />
            <ChangePasswordForm />
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default ChangePassword