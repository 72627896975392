import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LoginBanner from '../../components/LoginBanner'

const SignUp = () => {
    return (
        <>
            <Header bgColor={false} />
            <LoginBanner route="signup" />
            <CallToActionBanner bgColored={false} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#143975" />
            <Footer />
        </>
    )
}

export default SignUp