import React from 'react'
import Routes from './Routes/index'
import './App.css'

export default function App() {
  return (
    <div>
      <Routes />
    </div>
  )
}
