import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GoogleButton from '../assets/images/google-button.png';
import axios from '../api/axios';
import { RxUpload } from 'react-icons/rx'
import { BiLoaderAlt } from 'react-icons/bi'


const CandidateSignupForm = () => {
    const navigate = useNavigate()
    const COUNTRIES_API_URL = "/countries";
    const TAGS_API_URL = "/tags";
    const INTERESTEDIN_API_URL = "/intin";
    const REGISTER_URL = "/register";
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');


    const [userNameError, setUserNameError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [firstNameError, setFirstNameError] = useState("")
    const [lastNameError, setLastNameError] = useState("")
    const [LinkedInError, setLinkedInError] = useState("")
    const [countryError, setCountryError] = useState("")
    const [fileError, setFileError] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [tagsError, setTagsError] = useState("")
    const [interestedError, setInterestedError] = useState("")
    const [agreeError, setAgreeError] = useState(false)
    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)


    const [uploadedResume, setUploadedResume] = useState()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [country, setCountry] = useState("")
    const [intrestedIn, setIntrestedIn] = useState([])
    const [tags, setTags] = useState([])
    const [linkedin, setLinkedin] = useState("")
    const [agree, setAgree] = useState(false)
    const [phone, setPhone] = useState("")
    const [phoneCode, setPhoneCode] = useState()
    const [phoneLength, setPhoneLength] = useState()
    const [countriesList, setCountriesList] = useState()
    const [tagsList, setTagsList] = useState()
    const [interestedInList, setInterestedInList] = useState()

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCountriesList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchTagsData = async () => {
        try {
            const response = await axios.get(TAGS_API_URL);
            setTagsList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchInterestedInData = async () => {
        try {
            const response = await axios.get(INTERESTEDIN_API_URL);
            setInterestedInList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCountriesData()
        fetchTagsData()
        fetchInterestedInData()
    }, [])

    useEffect(() => {
        if (phoneCode == "+1") {
            setPhoneLength(10)
        } else if (phoneCode == "+92") {
            setPhoneLength(10)
        } else if (phoneCode == "+389") {
            setPhoneLength(8)
        } else if (phoneCode == "+355") {
            setPhoneLength(8)
        } else if (phoneCode == "+387") {
            setPhoneLength(8)
        } else if (phoneCode == "+55") {
            setPhoneLength(11)
        }
    }, [phoneCode])

    const handleFileInput = (e) => {
        let file = e.target.files[0];
        console.log('file ', file)
        file?.name?.replace(" ", "-");
        setUploadedResume(file);
        setFileError("")
    };

    const handleMultipleTagsSelection = (e) => {
        const value = Array.from(e.target.selectedOptions, option => option.value);
        console.log(value)
        setTags(value)
        setTagsError("")
    }
    const handleMultipleInterestedTagsSelection = (e) => {
        const value = Array.from(e.target.selectedOptions, option => option.value);
        setIntrestedIn(value)
        setInterestedError("")
    }

    const onlyLettersAndSpaces = (str) => {
        return /^[A-Za-z\s]*$/.test(str);
    }


    const validateFirstName = () => {
        if (firstName == "") {
            setFirstNameError("First Name can not be empty")
        } else if (!onlyLettersAndSpaces(firstName)) {
            setFirstNameError("Only Alphabets are Allowed")
        } else {
            setFirstNameError("")
        }
    }

    const validateLastName = () => {
        if (lastName == "") {
            setLastNameError("Last Name can not be empty")
        } else if (!onlyLettersAndSpaces(lastName)) {
            setLastNameError("Only Alphabets are Allowed")
        } else {
            setLastNameError("")
        }
    }

    const validateEmail = () => {
        if (email == "") {
            setUserNameError("Username can not be empty")
        } else if (!regex.test(email)) {
            setUserNameError("Username must be a valid Email Address")
        } else {
            setUserNameError("")
        }
    }

    const validatePassword = () => {
        if (password == "") {
            setPasswordError("Password can not be empty")
        } else if (password.length < 6) {
            setPasswordError("Password must be at least 6 characters long")
        } else {
            setPasswordError("")
        }
    }


    const validateLInkedInURL = () => {
        if (linkedin == "") {
            console.log('aslskdjfkasdhfksad')
            setLinkedInError("Linked In Url can not be empty")
        } else {
            setLinkedInError("")
        }
    }

    const validateCountry = (e) => {
        if (e == "Country" || e == "") {
            setCountryError("Please select a valid Country")
        } else {
            setCountryError("")
        }
    }

    const validatePhoneNumber = () => {
        console.log(phone.length)
        if (phone == "") {
            setPhoneError("Phone can not be empty")
        } else if (phone.length < phoneLength) {
            setPhoneError(`Please enter a valid phone having ${phoneLength} numbers`)
        } else {
            setPhoneError("")
        }
    }

    const validateFileUploaded = () => {
        if (uploadedResume == "") {
            setFileError("Please Upload a File")
        } else {
            setFileError("")
        }
    }

    const validateTagsSelection = () => {
        console.log(tags.length)
        if (tags.length == 0) {
            setTagsError("Please select at least 1 Tech Tag")
        } else {
            setTagsError("")
        }
    }
    const validateInterestedTagsSelection = () => {
        if (intrestedIn.length == 0) {
            setInterestedError("Please select at least 1 Interested In Tag")
        } else {
            setInterestedError("")
        }
    }

    const validateIsAgree = () => {
        if (agree) {
            setAgreeError(false)
        } else {
            setAgreeError(true)
        }
    }

    const checkValidationOfAllFields = () => {
        validateFirstName()
        validateLastName()
        validateCountry(country)
        validatePassword()
        validateEmail()
        validateLInkedInURL()
        validatePhoneNumber()
        validateFileUploaded()
        validateTagsSelection()
        validateInterestedTagsSelection()
        validateIsAgree()
    }

    const registerUser = async () => {
        checkValidationOfAllFields()
        if (
            firstNameError == "" &&
            lastNameError == "" &&
            userNameError == "" &&
            countryError == "" &&
            phoneError == "" &&
            LinkedInError == "" &&
            phoneError == ""
        ) {
            setLoader(true)
            try {
                let post_data = {
                    "name": `${firstName} ${lastName}`,
                    "email": email,
                    "password": password,
                    "first_name": firstName,
                    "last_name": lastName,
                    "country_id": country,
                    // "resume": uploadedResume,
                    // "resume":"pdf/doc",
                    // "file": uploadedResume, // resume pdf/doc, later it will be access domain/upload/file
                    "mobile": `${phoneCode}${phone}`,
                    "tech_tags": tags,
                    "interested_in": intrestedIn,
                    "is_term_accept": agree ? 1 : 0,
                    "user_type": 1 // 1 for candidate, 2 for employer
                }

                console.log('post date', post_data)

                const response = await axios.post(
                    REGISTER_URL,
                    JSON.stringify(post_data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                console.log("response", response)
                const obj = {
                    userRole: "1",
                    token: response.data.token
                }
                localStorage.setItem('userInfo', JSON.stringify(obj))
                navigate('/profile')
            } catch (err) {
                console.log('error ', err.response.data.message)
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
        } else {
            alert('asdfasdfd')
        }

        setTimeout(() => {
            setLoader(false)
            setApiResponseError("")
        }, 3000)

    }

    return (
        <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
            <h2 className='font-family-700 font-medium text-center primary-heading-color mb-8'>
                Create Account
            </h2>
            <div className="flex align-middle justify-center flex-col items-center w-3-12-5 mx-auto">
                <button className='google-btn-bg-color w-full relative cursor-pointer rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Continue to Google
                    <img className='absolute top-0 left-0 z-10 cursor-pointer' src={GoogleButton} alt="" />
                </button>
                <span className='my-6 relative border border-gray-300 xl:w-full lg:w-4/5 md:w-4/5 mx-auto sm:w-full w-full'>
                    <span className='absolute top-1/2 left-1/2 origin-center text-lg font-family-500 font-normal secondary-heading-color px-6' style={{ transform: "translate(-50%,-50%)", background: "#fff" }}>
                        Or
                    </span>
                </span>
                <div className="flex mb-6 align-middle justify-between  w-full gap-6">
                    <div className="flex flex-col w-full">
                        <input
                            type="text"
                            autoComplete='off'
                            className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                            id="firstname"
                            onBlur={validateFirstName}
                            value={firstName}
                            placeholder="First Name"
                        />
                        {
                            firstNameError ?
                                <span className='text-red-700 text-lg font-family-500 font-medium pt-3 block w-full'>
                                    {firstNameError}
                                </span>
                                : ""
                        }
                    </div>
                    <div className="flex flex-col w-full">
                        <input
                            type="text"
                            autoComplete='off'
                            className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                            onBlur={validateLastName}
                            id="lastname"
                            value={lastName}
                            placeholder="Last Name"
                        />
                        {
                            lastNameError ?
                                <span className='text-red-700 text-lg font-family-500 font-medium pt-3 block w-full'>
                                    {lastNameError}
                                </span>
                                : ""
                        }
                    </div>
                </div>
                <div className="flex flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <input
                        type="email"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        onBlur={validateEmail}
                        value={email}
                        id="email"
                        placeholder="E-Mail"
                    />
                    {
                        userNameError ?
                            <span className='text-red-700 text-lg font-family-500 font-medium pt-3 block w-full'>
                                {userNameError}
                            </span>
                            : ""
                    }
                </div>
                <div className="flex flex-col xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10 align-middle justify-between items-center w-full gap-0">
                    <input
                        type="password"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out  focus:bg-white focus:border-gray-300 focus:outline-none"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        onBlur={validatePassword}
                        value={password}
                        id="password"
                        placeholder="Password"
                    />
                    {
                        passwordError ?
                            <span className='text-red-700 text-lg font-family-500 pt-3 font-medium block w-full'>
                                {passwordError}
                            </span>
                            : ""
                    }
                </div>
                <div className="flex flex-col mb-6 align-middle justify-between items-center w-full gap-0 relative">
                    <select id="countries"
                        onChange={(e) => {
                            // validateCountry(e)
                            if (e.target.value != "Country") {
                                setCountry(e.target.value)
                                setCountryError("")
                            } else {
                                setCountryError("Please select a valid Contry")
                            }
                        }}
                        className="bg-white border font-family-700 font-normal appearance-none border-gray-300 outline-none rounded-full focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                        <option selected>Country</option>
                        {
                            countriesList?.map((item, index) => {
                                return (
                                    <option value={item.id} key={index}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                    <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                    </svg>
                    {
                        countryError ?
                            <span className='text-red-700 text-lg font-family-500 pt-3 font-medium block w-full'>
                                {countryError}
                            </span>
                            : ""
                    }
                </div>

                <div className="flex mb-6 align-middle justify-between items-center w-full gap-0 relative border border-solid border-gray-300 rounded-full transition ease-in-out">
                    <select
                        onChange={(e) => {
                            setPhoneCode(e.target.value)
                        }}
                        id="mobileCode" className="xl:w-3/12 lg:w-3/12 md:w-5/12 sm:w-5/12 w-5/12 bg-white appearance-none outline-none rounded-full border-none focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block pl-4 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                        <option selected>code</option>
                        {
                            countriesList?.map((item, index) => {
                                return (
                                    <option value={item.phone_code} key={index}>{item.phone_code}</option>
                                )
                            })
                        }
                    </select>
                    <input
                        maxLength={phoneLength}
                        minLength={phoneLength}
                        type="text"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full pr-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        onChange={(e) => {
                            setPhone(e.target.value)
                        }}
                        value={phone}
                        onBlur={validatePhoneNumber}
                        id="mobile"
                        placeholder="Mobile"
                    />
                </div>
                {
                    phoneError ?
                        <span className='text-red-700 text-lg font-family-500 pb-3 font-medium block w-full'>
                            {phoneError}
                        </span>
                        : ""
                }
                <div className="flex mb-6 align-middle justify-between rounded-3xl items-center w-full gap-6">
                    <select
                        multiple
                        onChange={(e) => {
                            handleMultipleInterestedTagsSelection(e)
                            // setTags(e.target.selectedOptions)
                        }}
                        id="interestedIn" className="w-full bg-white appearance-none outline-none rounded-3xl border border-solid border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block pl-4 py-2  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                        {
                            interestedInList?.map((item, index) => {
                                return (
                                    <option value={item.id} key={index}>{item.title}</option>
                                )
                            })
                        }
                    </select>
                    {/* <input
                        type="text"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        // onChange={(e) => {
                        //     setUsername(e.target.value)
                        // }}
                        id="interestedIn"
                        // value={username}
                        placeholder="I am interested in"
                    /> */}
                </div>
                {
                    interestedError ?
                        <span className='text-red-700 text-lg font-family-500 pb-3 font-medium block w-full'>
                            {interestedError}
                        </span>
                        : ""
                }
                <div className={`flex ${tagsError == "" ? "xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10" : "xl:mb-5 lg:mb-5 md:mb-10 sm:mb-10 mb-10"} align-middle justify-between items-center w-full gap-6 relative border border-solid border-gray-300 rounded-3xl transition ease-in-out`}>
                    <select
                        multiple
                        onChange={(e) => {
                            handleMultipleTagsSelection(e)
                            // setTags(e.target.selectedOptions)
                        }}
                        id="tags" className="w-full bg-white appearance-none outline-none rounded-3xl border-none focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block pl-4 py-2  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                        {
                            tagsList?.map((item, index) => {
                                return (
                                    <option value={item.id} key={index}>{item.tag}</option>
                                )
                            })
                        }
                    </select>
                    {/* <input
                        type="text"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        // onChange={(e) => {
                        //     setUsername(e.target.value)
                        // }}
                        id="techTags"
                        // value={username}
                        placeholder="Tech Tags"
                    /> */}
                </div>
                {
                    tagsError ?
                        <span className='text-red-700 text-lg font-family-500 pb-10 font-medium block w-full'>
                            {tagsError}
                        </span>
                        : ""
                }
                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6 relative border border-solid border-gray-300 rounded-full transition ease-in-out">
                    <div className="flex absolute top-0 left-0 w-full h-full align-middle justify-between px-8 py-2 items-center">
                        <span className='form-control cursor-pointer font-family-700 font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color'>
                            {
                                uploadedResume ?
                                    uploadedResume?.name
                                    :
                                    "Upload Resume"
                            }
                        </span>
                        <RxUpload className=' text-xl secondary-secondary-heading-color font-medium font-family-500' width="20px" height="20px" style={{ color: "#4F4F4F" }} />
                    </div>

                    <input
                        type="file"
                        onChange={(e) => {
                            handleFileInput(e)
                        }}
                        autoComplete='off'
                        className="form-control cursor-pointer relative opacity-0 font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        // onChange={(e) => {
                        //     setUsername(e.target.value)
                        // }}
                        id="resume"
                        // value={username}
                        placeholder="Upload Resume"
                    />
                </div>
                {
                    fileError ?
                        <span className='text-red-700 text-lg font-family-500 pb-3 font-medium block w-full'>
                            {fileError}
                        </span>
                        : ""
                }
                <div className="flex flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <input
                        type="text"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        onChange={(e) => {
                            setLinkedin(e.target.value)
                        }}
                        onBlur={validateLInkedInURL}
                        value={linkedin}
                        id="linkedInLink"
                        placeholder="LinkedIn Link"
                    />
                    {
                        LinkedInError ?
                            <span className='text-red-700 text-lg font-family-500 pt-3 font-medium block w-full'>
                                {LinkedInError}
                            </span>
                            : ""
                    }
                </div>
                <div className={`flex align-middle w-full ${agreeError ? "xl:mb-5 lg:mb-5 md:mb-10 sm:mb-5 mb-5" : "xl:mb-16 lg:mb-14 md:mb-10 sm:mb-5 mb-5"} items-center `}>
                    <label className="relative flex align-middle justify-center items-center custom-checkbox">
                        <input
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setAgreeError(false)
                                } else {
                                    setAgreeError(true)
                                }
                                setAgree(e.target.checked)

                            }}
                            value={agree}
                            type="checkbox"
                        // I
                        // value={rememberMe} 
                        />
                        <span className="checkmark" style={{ borderColor: "#BDBDBD" }}></span>
                        <span className="form-check-label xl:leading-none lg:leading-none md:leading-none sm:leading-none leading-tight pl-9 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 font-medium inline-block secondary-heading-color" htmlFor="rememberMe">
                            Yes, I understand and Agree to the Terms of Service
                        </span>
                    </label>
                </div>
                {
                    agreeError ?
                        <span className='text-red-700 text-lg font-family-500 pb-10 font-medium block w-full'>
                            Please accept Terms and Service to Proceed
                        </span>
                        : ""
                }
                <button
                    onClick={registerUser}
                    disabled={firstNameError || lastNameError || userNameError || tagsError || interestedError || passwordError || countryError || phoneError || agreeError || LinkedInError}
                    className='primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Create
                    {
                        loader ?
                            <BiLoaderAlt className='loader-spinner ml-2' />
                            :
                            null
                    }
                </button>
                {
                    apiResponseError ?
                        <span className='text-red-700 text-lg font-family-500 font-medium py-3 block w-full'>
                            {apiResponseError}
                        </span>
                        :
                        ""
                }
                <a className='secondary-heading-color w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                    Already have account?
                    <span onClick={() => {
                        navigate('/candidate-login')
                    }} className='call-to-action-text-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                        Log in
                    </span>
                </a>
            </div>
            <div className=" xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
                <hr className='border border-gray-300 my-0 mx-auto w-full' />
            </div>
        </div >
    )
}

export default CandidateSignupForm