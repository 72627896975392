import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LandingPage from '../pages/LandingPage'
// import ServicePage from '../Pages/ServicePage'
import Login from '../pages/Login'
import Signup from '../pages/SignUp'
import EmployLogin from '../pages/Login/employerLogin'
import CandidateLogin from '../pages/Login/candidateLogin'
import CandidateSignup from '../pages/SignUp/candidateSignup'
import ForgotPass from '../pages/ForgotPass'
import EmployeeSignup from '../pages/SignUp/employeeSignup'
import ChangePassword from '../pages/Profile/ChangePassword'
import Profile from '../pages/Profile'
// import Canada from '../pages/Canada'
// import NorthMacedonia from '../pages/North-Macedonia'
// import USA from '../pages/USA'
// import Pakistan from '../pages/Pakistan'
// import Albania from '../pages/Albania'
// import Bosnia from '../pages/Bosnia'
// import Brazil from '../pages/Brazil'
// import EmployerSingupStepTwo from '../pages/Signup/EmployerSignup/StepTwo'
// import EmployerSingupStepThree from '../pages/Signup/EmployerSignup/StepThree'
// import FindTalentSignup from '../pages/Signup/FindTalentSignup'
// import CompanyInformtion from '../pages/CompanyInfo'
// import FindJob from '../pages/FindJob'
// import CandidateDashboard from '../pages/CandidateDashboard'
// import ProfileEdit from '../pages/Profile/EditProfile'
// import EmployerDashboard from '../pages/EmployerDashboard'
// import MatchResumeStepOne from '../pages/Profile/MatchResume/StepOne'

export default function index() {
    return (
        <div className="main-div">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/candidateOrEmployer" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/employer-login" element={<EmployLogin />} />
                    <Route path="/candidate-login" element={<CandidateLogin />} />
                    <Route path="/candidate-signup" element={<CandidateSignup />} />
                    <Route path="/employer-signup" element={<EmployeeSignup />} />
                    <Route path="/forgot-pass" element={<ForgotPass />} />
                    <Route path="/profile/change-password" element={<ChangePassword />} />
                    <Route path="/profile" element={<Profile />} />

                    {/* <Route path="/services" element={<ServicePage />} />
                    <Route path="/canada" element={<Canada />} />
                    <Route path="/north-macedonia" element={<NorthMacedonia />} />
                    <Route path="/usa" element={<USA />} />
                    <Route path="/pakistan" element={<Pakistan />} />
                    <Route path="/albania" element={<Albania />} />
                    <Route path="/bosnia" element={<Bosnia />} />
                    <Route path="/brazil" element={<Brazil />} />                    
                    <Route path="/find-talent" element={<FindTalentSignup />} />
                    <Route path="/company-info" element={<CompanyInformtion />} />
                    <Route path="/find-job" element={<FindJob />} />
                    <Route path="/candidate-dashboard" element={<CandidateDashboard />} />
                    <Route path="/profile/edit-profile" element={<ProfileEdit />} />
                    <Route path="/employer-dashboard" element={<EmployerDashboard />} />
                    <Route path="/match-resume-step-one" element={<MatchResumeStepOne />} /> */}

                </Routes>
            </BrowserRouter>
        </div>
    )
}
