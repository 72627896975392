import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
import CompanyLogo2 from '../assets/images/company-card-1.png'
import CompanyLogo3 from '../assets/images/company-card-2.png'

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";

const CompanyInfoCard = () => {
    const swiperDefault = useSwiper();
    const navigate = useNavigate()
    const CompanyList = [
        { Logo: CompanyLogo1, name: "Company Name", detail: "Position for which they are hiring position for which they are hiring position for" },
        { Logo: CompanyLogo2, name: "Company Name", detail: "Position for which they are hiring position for which they are hiring position for" },
        { Logo: CompanyLogo3, name: "Company Name", detail: "Position for which they are hiring position for which they are hiring position for" },
        { Logo: CompanyLogo1, name: "Company Name", detail: "Position for which they are hiring position for which they are hiring position for" },
        { Logo: CompanyLogo2, name: "Company Name", detail: "Position for which they are hiring position for which they are hiring position for" },
    ]
    return (
        <div className="main-container">
            {/* xl:grid lg:grid md:grid sm:grid hidden */}
            <div className="xl:grid lg:grid md:grid sm:grid hidden xl:grid-cols-5 xl:gap-8 lg:gap-6 md:gap-6 gap-6 lg:grid-cols-4 md:grid-cols-3  sm:grid-cols-2 grid-cols-1 xl:py-20 lg:py-14 md:py-10 py-10  ">
                {CompanyList.map((items) => {
                    return (
                        <div className="company-card py-5 px-8 "
                        // onClick={() => { navigate('/company-info') }}
                        >
                            <img src={items.Logo} className="mb-2" alt="" />
                            <h5 className='mb-2 font-normal' >
                                {items.name}
                            </h5>
                            <p className='mb-0 text-base font-normal ' >
                                {items.detail}
                            </p>
                        </div>
                    )
                })}
            </div>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper xl:hidden lg:hidden md:hidden sm:hidden block xl:py-20 lg:py-14 md:py-10 py-10">
                {CompanyList.map((items) => {
                    return (
                        <SwiperSlide>
                            <div className="company-card py-6 px-14 "
                            // onClick={() => { navigate('/company-info') }}
                            >
                                <img src={items.Logo} className="mb-2 block w-max mx-auto" alt="" />
                                <h5 className='mb-2 text-2xl font-medium font-family-500 text-center' >
                                    {items.name}
                                </h5>
                                <p className='mb-0 text-xl font-medium font-family-500 text-center ' >
                                    {items.detail}
                                </p>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

        </div>
    )
}

export default CompanyInfoCard