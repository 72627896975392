import React, { Component, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
import BannerImageMobile from '../assets/images/banner-mobile.png';
const ForgotPasswordForm = () => {
    const [username, setUsername] = useState("")    
    const navigate = useNavigate()
    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-10 md:pt-16 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-5 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" className='xl:block lg:block md:block sm:hidden hidden' />
                    <img src={BannerImageMobile} alt="" className='xl:hidden lg:hidden md:hidden sm:block block' />
                </div>
                <div className="content flex align-middle xl:w-2/5 lg:w-1/2 md:w-11/12 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:mb-9 lg:mb-9 md:mb-9 sm:mb-5 mb-5 text-center font-family-700 font-extrabold'>
                        Forgot Password
                    </h1>
                    <input
                        type="email"
                        autoComplete='off'
                        className="form-control font-family-700 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-white rounded-full transition ease-in-out xl:mb-10 lg:mb-10 md:mb-10 sm:mb-5 mb-5 focus:bg-white focus:border-white focus:outline-none"
                        // onChange={(e) => {
                        //     setUsername(e.target.value)
                        // }}
                        id="email"
                        // value={username}
                        placeholder="Email"
                    />
                    <button className='primary-bg-color mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Submit
                    </button>
                </div>
            </div>
        </div >
    )
}

export default ForgotPasswordForm