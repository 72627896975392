import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
const CallToActionBanner = ({
    mainHeading,
    bgColored,
    secondaryHeading,
    textColor
}) => {
    const navigate = useNavigate()
    return (
        <div className={`${bgColored ? 'call-to-action' : ""} xl:py-20 lg:py-14 md:py-10 py-10 font-family-500`} style={{ color: textColor, }}>
            <div className="main-container">
                <h2 className={`text-center font-family-500 xl:mb-3 lg:mb-3 md:mb-5 mb-7 xl:w-2/3 w-full text-6-5-xl mx-auto font-normal`}>
                    {mainHeading}
                </h2>
                <h2 className={`text-6-5-xl font-family-500 text-center xl:mb-12 lg:mb-12 md:mb-11 mb-10  font-semibold`}>
                    {secondaryHeading}
                </h2>
                <a  className={`flex align-middle font-family-500 ${bgColored ? 'border-white' : ""} justify-center cursor-pointer transition-all items-center w-max mx-auto xl:h-16 lg:h-16 md:h-11 h-11 text-3xl rounded-full xl:border-4 lg:border-4 md:border-2 border-2`} style={{ borderColor: textColor }}>
                    <button className=' xl:text-3xl lg:text-3xl md:text-2xl text-2xl uppercase font-family-500 font-medium xl:px-11 lg:px-10 md:px-8 px-7' >
                        Contact Us
                    </button>
                </a>
            </div>
        </div >
    )
}

export default CallToActionBanner