import React, { Component, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
import BannerImageMobile from '../assets/images/banner-mobile.png';
import GoogleButton from '../assets/images/google-button.png';
import axios from '../api/axios';
import { BiLoaderAlt } from 'react-icons/bi'
const CandidateLoginForm = () => {
    const LOGIN_URL = "/login";

    const [userName, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [userNameError, setUserNameError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const handleUserNameChange = (e) => {
        setUsername(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }


    const validateUserName = () => {
        if (userName == "") {
            setUserNameError("Username can not be empty")
        } else if (!regex.test(userName)) {
            setUserNameError("Username must be a valid Email Address")
        } else {
            const spacesRemoved = userName.replace(/\s/g, '')
            setUsername(spacesRemoved)
            setUserNameError("")
        }
    }

    const validatePassword = () => {
        if (password == "") {
            setPasswordError("Password can not be empty")
        } else if (password.length < 6) {
            setPasswordError("Password must be at least 6 characters long")
        } else {
            setPasswordError("")
        }
    }

    const loginWithAPi = async () => {
        setLoader(true)
        validateUserName()
        validatePassword()
        if (userNameError == "" && passwordError == "") {
            try {
                let post_data = {
                    "email": userName,
                    "password": password
                }

                const response = await axios.post(
                    LOGIN_URL,
                    JSON.stringify(post_data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                const obj = {
                    userRole: "1",
                    token: response.data.token
                }
                localStorage.setItem('userInfo', JSON.stringify(obj))
                navigate('/profile')
            } catch (err) {
                console.log('error ', err.response.data.message)
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
            setTimeout(() => {
                setLoader(false)
                setApiResponseError("")
            }, 3000)
        }

    }

    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-20 md:pt-24 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-5 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" className='xl:block lg:block md:block sm:hidden hidden' />
                    <img src={BannerImageMobile} alt="" className='xl:hidden lg:hidden md:hidden sm:block block' />
                </div>
                <div className="content flex align-middle xl:w-2/5 lg:w-1/2 md:w-3/5 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:mb-9 lg:mb-9 md:mb-9 sm:mb-5 mb-5 text-center font-family-700 font-extrabold'>
                        Sign In
                    </h1>
                    <button className='bg-white relative cursor-pointer rounded-full px-8 py-2 call-to-action-text-color xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Continue to Google
                        <img className='absolute top-0 left-0 z-10 cursor-pointer' src={GoogleButton} alt="" />
                    </button>
                    <span className='my-6 relative border border-white xl:w-full lg:w-4/5 md:w-4/5 mx-auto sm:w-full w-full'>
                        <span className='absolute top-1/2 left-1/2 origin-center text-lg font-family-500 font-normal text-white px-6' style={{ transform: "translate(-50%,-50%)", background: "#69abba" }}>
                            Or
                        </span>
                    </span>
                    <input
                        type="text"
                        autoComplete='off'
                        className="form-control font-family-700 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-white rounded-full transition ease-in-out xl:mb-6 lg:mb-6 md:mb-6 sm:mb-5 mb-5 focus:bg-white focus:border-white focus:outline-none"
                        onChange={(e) => {
                            handleUserNameChange(e)
                            // setUsername(e.target.value)
                        }}
                        id="username"
                        onBlur={validateUserName}
                        value={userName}
                        placeholder="Username"
                    />
                    {
                        userNameError ?
                            <span className='text-red-700 text-lg font-family-500 font-medium pb-3 block w-full'>
                                {userNameError}
                            </span>
                            : ""
                    }
                    <input
                        type="password"
                        className="form-control font-family-700 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-white rounded-full transition ease-in-out xl:mb-6 lg:mb-6 md:mb-6 sm:mb-5 mb-5 focus:bg-white focus:border-white focus:outline-none"
                        onChange={(e) => {
                            handlePasswordChange(e)
                        }}
                        id="password"
                        onBlur={validatePassword}
                        value={password}
                        autoComplete='off'
                        placeholder="Password"
                    />
                    {
                        passwordError ?
                            <span className='text-red-700 text-lg font-family-500 pb-3 font-medium block w-full'>
                                {passwordError}
                            </span>
                            : ""
                    }
                    <div className="flex align-middle items-center xl:mb-10 lg:mb-10 md:mb-10 sm:mb-5 mb-5">
                        <label className="relative flex align-middle justify-center items-center custom-checkbox">
                            <input type="checkbox" onChange={(e) => {
                                setRememberMe(e.target.checked)
                            }} value={rememberMe} />
                            <span className="checkmark"></span>
                            <span className="form-check-label xl:leading-none lg:leading-none md:leading-none sm:leading-none leading-tight pl-9 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 font-medium inline-block text-white" htmlFor="rememberMe">
                                Remember me
                            </span>
                        </label>
                        {/* <div className="form-group flex align-middle items-center form-check">
                            <input
                                type="checkbox"
                                className="form-check-input h-5 w-5 border border-white rounded-sm bg-transparent checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-300 align-top bg-no-repeat bg-center bg-contain float-left mr-3 cursor-pointer"
                                id="rememberMe"
                                onChange={(e) => {
                                    setRememberMe(e.target.checked)
                                }}
                                checked={rememberMe}
                            />
                            <label className="form-check-label text-lg font-family-500 font-medium inline-block text-white" htmlFor="rememberMe">Remember me</label>
                        </div> */}
                    </div>
                    <button onClick={loginWithAPi} disabled={userNameError || passwordError || loader} className='primary-bg-color disabled:opacity-80 disabled:cursor-not-allowed mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Sign In
                        {
                            loader ?
                                <BiLoaderAlt className='loader-spinner ml-2' />
                                :
                                null
                        }
                    </button>
                    {
                        apiResponseError ?
                            <span className='text-red-700 text-lg font-family-500 font-medium pb-3 block w-full'>
                                {apiResponseError}
                            </span>
                            :
                            ""
                    }
                    <a className='text-white w-full text-center xl:mb-2 lg:mb-2 md:mb-2 sm:mb-3 mb-3 block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                        Don’t have an account?
                        <span onClick={() => {
                            navigate('/candidate-signup')
                        }} className='secondary-link-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                            Sign up
                        </span>
                    </a>
                    <a onClick={() => {
                        navigate('/forgot-pass')
                    }} className='secondary-link-color block text-center w-full mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                        Forgot password?
                    </a>
                </div>
            </div>
        </div >
    )
}

export default CandidateLoginForm