import React, { Component, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GoogleButton from '../assets/images/google-button.png';
import { RxUpload } from 'react-icons/rx'
import CanadaLogo from '../assets/images/canada.png'
import USALogo from '../assets/images/usa.png'
import PakistanLogo from '../assets/images/pakistan.png'
import MacedoniaLogo from '../assets/images/Macedonia.png'
import AlbaniaLogo from '../assets/images/albania.png'
import BosniaLogo from '../assets/images/bosnia.png'
import BrazilLogo from '../assets/images/brazil.png'

const EmployeeSignupForm = () => {
    const navigate = useNavigate()

    const [uploadedResume, setUploadedResume] = useState()
    const [stepCounter, setStepCounter] = useState(1)
    const [imageData, setImageData] = useState("")

    const MobileCode = [
        { value: "+355", label: "+355" },
        { value: "+387", label: "+387" },
        { value: "+55", label: "+55" },
        { value: "+995", label: "+995" },
        { value: "+389", label: "+389" },
        { value: "+92", label: "+92" },
        { value: "+1", label: "+1" },
    ]

    const handleFileInput = (e) => {
        let file = e.target.files[0];
        console.log('file ', file)
        file?.name?.replace(" ", "-");
        setUploadedResume(file);
        setImageData(URL.createObjectURL(file));
    };

    return (
        <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
            <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                <div className="flex relative steps-row justify-between w-full align-middle  mb-10 xl:items-center lg:items-center md:items-center sm:items-center items-start">
                    <div
                        onClick={() => {
                            setStepCounter(1)
                        }}
                        className="flex cursor-pointer align-middle first justify-start items-center flex-col ">
                        <span className='text-2xl bg-white secondary-heading-color secondary-border-color font-family-500 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full'>
                            1
                        </span>
                        <span className='text-xl font-family-500 font-medium text-center secondary-heading-color'>
                            Sign Up
                        </span>
                    </div>
                    <div
                        onClick={() => {
                            setStepCounter(2)
                        }}
                        className={`cursor-pointer ${stepCounter == 2 ? "step-two" : stepCounter == 3 ? "step-three" : ""} flex align-middle second justify-center items-center flex-col before-after relative`}>
                        <span className={`  text-2xl bg-white secondary-heading-color ${stepCounter == 1 ? "steps-main-border-color" : "secondary-border-color"}  font-family-500 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full`}>
                            2
                        </span>
                        <span className='text-xl font-family-500 font-medium text-center secondary-heading-color'>
                            Create Profile
                        </span>
                    </div>
                    <div
                        onClick={() => {
                            setStepCounter(3)
                        }} className="flex  cursor-pointer align-middle third justify-end items-center flex-col">
                        <span className={` text-2xl bg-white secondary-heading-color ${stepCounter == 1 || stepCounter == 2 ? "steps-main-border-color" : "secondary-border-color"} font-family-500 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full`}>
                            3
                        </span>
                        <span className='text-xl font-family-500 font-medium text-center secondary-heading-color'>
                            Post Job Globally
                        </span>
                    </div>
                </div>
            </div>
            {
                stepCounter == 1 ?
                    <>
                        <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                            <h2 className='font-family-700 font-medium text-center primary-heading-color mb-9'>
                                Create Employer Account
                            </h2>
                            <div className="flex xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col mb-6 align-middle justify-between items-center w-full gap-6">
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                    // onChange={(e) => {
                                    //     setUsername(e.target.value)
                                    // }}
                                    id="firstname"
                                    // value={username}
                                    placeholder="First Name"
                                />
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                    // onChange={(e) => {
                                    //     setUsername(e.target.value)
                                    // }}
                                    id="lastname"
                                    // value={username}
                                    placeholder="Last Name"
                                />
                            </div>
                            <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                <input
                                    type="email"
                                    autoComplete='off'
                                    className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                    // onChange={(e) => {
                                    //     setUsername(e.target.value)
                                    // }}
                                    id="email"
                                    // value={username}
                                    placeholder="E-Mail"
                                />
                            </div>
                            <div className="flex xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10 align-middle justify-between items-center w-full gap-6">
                                <input
                                    type="password"
                                    autoComplete='off'
                                    className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out  focus:bg-white focus:border-gray-300 focus:outline-none"
                                    // onChange={(e) => {
                                    //     setUsername(e.target.value)
                                    // }}
                                    id="password"
                                    // value={username}
                                    placeholder="Password"
                                />
                            </div>
                            <div className="flex mb-6 align-middle justify-between items-center w-full gap-6 relative">
                                <select id="countries" class="bg-white border appearance-none border-gray-300 outline-none rounded-full focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                                    <option selected>Country</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Usa">USA</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Bosnia">Bosnia</option>
                                    <option value="Brazil">Brazil</option>
                                </select>
                                <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                                </svg>
                            </div>

                            <div className="flex mb-6 align-middle justify-between items-center w-full gap-0 relative border border-solid border-gray-300 rounded-full transition ease-in-out">
                                <select id="mobileCode" class="xl:w-3/12 lg:w-3/12 md:w-5/12 sm:w-5/12 w-5/12 bg-white appearance-none outline-none rounded-full border-none focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block pl-4 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                                    {
                                        MobileCode?.map((item) => {
                                            return (
                                                <option value={item?.label} >{item?.label}</option>
                                            )
                                        })
                                    }
                                </select>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="form-control font-family-500 font-normal block w-full pr-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                    // onChange={(e) => {
                                    //     setUsername(e.target.value)
                                    // }}
                                    id="mobile"
                                    // value={username}
                                    placeholder="Mobile"
                                />
                            </div>
                            <div className="flex align-middle w-full items-center xl:mb-14 lg:mb-14 md:mb-10 sm:mb-5 mb-5">
                                <label className="relative flex align-middle justify-center items-center custom-checkbox">
                                    <input type="checkbox"
                                    // I
                                    // value={rememberMe} 
                                    />
                                    <span className="checkmark" style={{ borderColor: "#BDBDBD" }}></span>
                                    <span className="form-check-label xl:leading-none lg:leading-none md:leading-none sm:leading-none leading-tight pl-9 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 font-medium inline-block secondary-heading-color" htmlFor="rememberMe">
                                        Yes, I understand and Agree to the Terms of Service
                                    </span>
                                </label>
                            </div>
                            <button onClick={() => {
                                setStepCounter(stepCounter + 1)
                            }} className='primary-bg-color w-full mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                                Create
                            </button>
                            <a className='secondary-heading-color w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                                Already have account?
                                <span onClick={() => {
                                    navigate('/employer-login')
                                }} className='call-to-action-text-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                                    Log in
                                </span>
                            </a>
                        </div>
                        <div className="hr-bottom xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
                            <hr className='border border-gray-300 my-0 mx-auto w-full' />
                        </div>
                    </>
                    :
                    stepCounter == 2 ?
                        <>
                            <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                                <h2 className='font-family-700 font-medium text-center primary-heading-color mb-9'>
                                    Create Company Profile
                                </h2>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="websiteUrl"
                                        // value={username}
                                        placeholder="Website URL"
                                    />
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    />
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    {/* <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    /> */}
                                    <textarea id="companyInformation" className="form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder="Company Information">
                                    </textarea>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="linkedIn"
                                        // value={username}
                                        placeholder="LinkedIn Link"
                                    />
                                </div>
                                <div className="flex mb-12 align-middle justify-between items-center w-full gap-6 relative transition ease-in-out">
                                    {/* <div className="flex absolute top-0 left-0 w-full h-full align-middle justify-start px-8 py-2 items-center"> */}
                                    <div className="flex w-full h-full align-middle gap-6 items-center">
                                        {
                                            imageData != "" ?
                                                <span className='h-20 uploader-bg-color w-20 border border-gray-400 rounded-lg  object-cover block' style={{ minWidth: "80px" }}>
                                                    <img src={imageData} alt="" className='w-full h-full object-cover' />
                                                </span>
                                                :
                                                <span className='uploader-bg-color flex align-middle justify-center items-center h-20 rounded-lg' style={{ minWidth: "20px", padding: "0px 31.46px" }}>
                                                    <RxUpload className=' text-xl secondary-secondary-heading-color font-medium font-family-500' width="20px" height="20px" style={{ color: "#4F4F4F" }} />
                                                </span>
                                        }
                                        <span className='form-control relative cursor-pointer font-family-500 font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color'>
                                            <span className='form-control flex align-middle items-center absolute top-0 left-0 h-full justify-start cursor-pointer font-family-500 font-normal w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color'>
                                                Upload Logo
                                            </span>
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleFileInput(e)
                                                }}
                                                autoComplete='off'
                                                className="form-control cursor-pointer relative opacity-0 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                                // onChange={(e) => {
                                                //     setUsername(e.target.value)
                                                // }}
                                                id="resume"
                                                // value={username}
                                                placeholder="Upload Resume"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <button onClick={() => {
                                    setStepCounter(stepCounter + 1)
                                }} className='primary-bg-color w-full mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                                    Create
                                </button>
                            </div>
                            <div className="hr-bottom xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
                                <hr className='border border-gray-300 my-0 mx-auto w-full' />
                            </div>
                        </>
                        :
                        <>
                            <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                                <h2 className='font-family-700 font-medium text-center primary-heading-color mb-9'>
                                    Post your Jobs Globally
                                </h2>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="jobTitle"
                                        // value={username}
                                        placeholder="Choose a Job Title"
                                    />
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    {/* <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    /> */}
                                    <textarea id="requirements" className="form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder="Job Key Requirements - 5 or less lines">
                                    </textarea>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="Salary"
                                        // value={username}
                                        placeholder="Salary"
                                    />
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6 relative">
                                    <select id="jobType" class="bg-white border appearance-none border-gray-300 outline-none rounded-full focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block w-full font-family-500 font-normal  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                                        <option selected>Job Type</option>
                                        <option value="fullTime">Full Time</option>
                                        <option value="remote">Remote</option>
                                        <option value="hybrid">Hybrid</option>
                                    </select>
                                    <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                                    </svg>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="Skills"
                                        // value={username}
                                        placeholder="Skills"
                                    />
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    {/* <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    /> */}
                                    <textarea id="responsibilites" className="form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder="Roles and Responsibilities">
                                    </textarea>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    {/* <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    /> */}
                                    <textarea id="jobRequirements" className="form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder="Job Requirements">
                                    </textarea>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    {/* <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    /> */}
                                    <textarea id="niceToHave" className="form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder="Nice to Have">
                                    </textarea>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    {/* <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        // onChange={(e) => {
                                        //     setUsername(e.target.value)
                                        // }}
                                        id="companyName"
                                        // value={username}
                                        placeholder="Company Name"
                                    /> */}
                                    <textarea id="benefits" className="form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder="Benefits">
                                    </textarea>
                                </div>
                                <div className="flex mb-6 align-middle justify-between items-center w-full gap-6">
                                    <span className='font-family-500 font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color'>
                                        Select a Country/Countries to post a Job:
                                    </span>
                                </div>
                            </div>
                            <div className="xl:grid lg:grid md:grid mb-14 xl:w-3/5 lg:w-4/5 w-full mx-auto sm:grid flex flex-wrap xl:grid-cols-7 lg:grid-cols-7 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 gap-6 md:grid-cols-5 sm:grid-cols-3 align-middle justify-center items-center">
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={CanadaLogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">Canada</p>
                                </div>
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={USALogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">USA</p>
                                </div>
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={PakistanLogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">Pakistan</p>
                                </div>
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={BosniaLogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">Macedonia</p>
                                </div>
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={AlbaniaLogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">Albania</p>
                                </div>
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={MacedoniaLogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">Bosnia</p>
                                </div>
                                <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                                    <img src={BrazilLogo} alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">Brazil</p>
                                </div>
                            </div>
                            <div className="flex align-middle justify-between"></div>
                            <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                                <button onClick={() => {
                                    setStepCounter(stepCounter + 1)
                                }} className='primary-bg-color w-full rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                                    Post your Jobs Globally
                                </button>
                            </div>
                            <div className=" xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-3/4 lg:w-4/5 w-full mx-auto">
                                <hr className='border border-gray-300 my-0 mx-auto w-full' />
                            </div>
                        </>
            }
            {/* </div> */}

        </div >
    )
}

export default EmployeeSignupForm