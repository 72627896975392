import React, { Component } from 'react'
import CompanyCard from '../../components/CompanyCard'
import CompanyInfoCard from '../../components/CompanyInfoCard'

import CompanyLogo1 from '../../assets/images/company-card.png'
import CompanyLogo2 from '../../assets/images/company-card-1.png'
import CompanyLogo3 from '../../assets/images/company-card-2.png'

import CompanyImage1 from '../../assets/images/company-image.png'
import CompanyImage2 from '../../assets/images/company-image-1.png'
import CompanyImage3 from '../../assets/images/company-image-2.png'
import CallToActionBanner from '../../components/CallToActionBanner'
import CountryList from '../../components/CountryList'
import RecommendedJobs from '../../components/RecommendedJobs'
import HomeBanner from '../../components/HomeBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const LandingPage = () => {
    return (
        <>
            <Header bgColor={false} />
            <HomeBanner />
            <CompanyInfoCard />
            <div className="main-container">
                <h1 className='text-center primary-heading-color  w-full mx-auto font-semibold  xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                    We're here to ensure you achieve
                    breakthrough success
                </h1>
            </div>
            <div className="main-container">
                <div className="inner-container">
                    <CompanyCard
                        imagePosition="right"
                        companyTtitle="Company name"
                        companyLogo={CompanyLogo1}
                        companyImage={CompanyImage1}
                        companyDescription="     Position for which they are hiring position for which they are  for which they are hiringposition f hiringor which tiring position for which they hey are hare hiring position for which they are"
                    />
                    <CompanyCard
                        imagePosition="left"
                        companyTtitle="Company name"
                        companyLogo={CompanyLogo2}
                        companyImage={CompanyImage2}
                        companyDescription="     Position for which they are hiring position for which they are  for which they are hiringposition f hiringor which tiring position for which they hey are hare hiring position for which they are"
                    />
                    <CompanyCard
                        imagePosition="right"
                        companyTtitle="Company name"
                        companyLogo={CompanyLogo3}
                        companyImage={CompanyImage3}
                        companyDescription="     Position for which they are hiring position for which they are  for which they are hiringposition f hiringor which tiring position for which they hey are hare hiring position for which they are"
                    />
                </div>
            </div>
            <div className="call-to-action xl:py-20 lg:py-14 md:py-10 py-10">
                <div className="main-container">
                    <h2 className='text-white text-center mb-14 font-semibold font-family-700 '>
                        Recomended Jobs
                    </h2>
                    <hr className='border mb-10' />
                    <RecommendedJobs />
                    <hr className='border my-10' />
                    <RecommendedJobs />
                    <hr className='border mt-10 mb-14' />
                    <a  className='flex align-middle justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                        <button className='text-white text-center text-xl font-family-500 font-medium'>
                            See more
                        </button>
                    </a>
                </div>
            </div>
            <div className="main-container">
                <div className="xl:py-20 lg:py-14 md:py-10 py-10">
                    <h2 className='primary-heading-color font-semibold text-center xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                        Countries, where we work
                    </h2>
                    <CountryList />
                </div>
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default LandingPage