import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
const RecommendedJobs = () => {
    const navigate = useNavigate()
    return (
        <div className="flex align-middle xl:flex-row lg:flex-row md:flex-col flex-col justify-between xl:gap-24 gap-10 recommended-job items-center">
            <div className="flex align-middle justify-center flex-col w-full">
                <div className="xl:flex lg:flex md:block items-center align-middle justify-between">
                    <h4 className='text-white font-medium xl:mb-0 lg:mb-0 md:mb-3 sm:mb-3 mb-5 font-family-500'>
                        Job Title - remote
                    </h4>
                    <div className="flex items-center xl:flex-nowrap lg:flex-nowrap md:flex-nowrap sm:flex-nowrap flex-wrap xl:w-3/5 lg:w-3/5 md:w-full w-full align-middle xl:justify-between lg:justify-between md:justify-start sm:justify-start xl:gap-0 lg:gap-0 md:gap-4 gap-4 justify-between ">
                        <p className='flex xl:w-max lg:w-max md:w-max sm:w-max w-2/5 align-middle justify-center items-center'>
                            <span className='mr-2'>
                                <img src={CompanyLogo1} className="mr-0" alt="" />
                            </span>
                            <span className='text-white font-family-500 text-xl font-medium'>Employer</span>
                        </p>
                        <span className='text-white xl:text-left lg:text-left md:text-left sm:text-left text-end xl:hidden lg:hidden md:hideen sm:hidden block font-family-500 text-xl xl:w-max lg:w-max md:w-max sm:w-max w-2/5 font-medium'>
                            7 Days ago
                        </span>
                        <span className='text-white font-family-500 text-xl xl:w-max lg:w-max md:w-max sm:w-max w-2/5 font-medium'>
                            Salary $500.00
                        </span>
                        <span className='text-white xl:text-left lg:text-left md:text-left sm:text-left text-end font-family-500 text-xl xl:w-max lg:w-max md:w-max sm:w-max w-2/5 font-medium'>
                            Full Time
                        </span>
                    </div>
                </div>
                <p className='text-white font-family-500 font-medium my-6 text-lg'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <ul className='flex align-middle items-center gap-4 xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center flex-wrap list-none'>
                    <li className='bg-white rounded-md px-4 h-6  call-to-action-color flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal'>
                        C++
                    </li>
                    <li className='bg-white rounded-md px-4 h-6  call-to-action-color flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal'>
                        Java
                    </li>
                    <li className='bg-white rounded-md px-4 h-6  call-to-action-color flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal'>
                        Angular
                    </li>
                    <li className='bg-white rounded-md px-4 h-6  call-to-action-color flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal'>
                        React
                    </li>
                    <li className='bg-white rounded-md px-4 h-6  call-to-action-color flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal'>
                        Microservices
                    </li>
                </ul>
            </div>
            <div className="flex align-middle justify-center xl:items-start lg:items-start md:items-start sm:items-start items-center xl:w-2/5 lg:w-2/5 md:w-full w-full flex-col">
                <a  className='bg-white w-max  px-6 xl:py-0 lg:py-2 md:py-2 xl:h-11 py-2 lg:h-auto h-auto ursor-pointer  xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4 mb-10 rounded-full border secondary-border-color  flex align-middle justify-center items-center'>
                    <button className='text-center text-lg font-family-500 font-normal secondary-link-color'>
                        View detailed Job Position
                    </button>
                </a>
                <span className='text-white xl:block lg:block md:block sm:block hidden text-lg mb-7 font-family-500 font-medium '>
                    7 Days ago
                </span>
                <p className='flex align-middle items-center xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4 mb-7'>
                    <span className='w-10'>
                        <svg className='mr-5' width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.660156 15.1721L2.56663 0.765625H9.98163L6.59191 7.96958H13.1595L2.56663 25.7656L4.68519 15.1721H0.660156Z" fill="white" />
                        </svg>
                    </span>
                    <span className='text-white text-lg font-family-500 font-medium'>
                        Apply Now
                    </span>
                </p>
                <p className='flex align-middle items-center '>
                    <span className='w-10'>
                        <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0523 15.5935C10.5162 15.7629 10.755 16.2763 10.5856 16.7403L9.71345 19.1289H22.9758C23.4697 19.1289 23.87 19.5293 23.87 20.0232C23.87 20.5171 23.4697 20.9174 22.9758 20.9174H9.45775C8.3398 20.9174 7.56229 19.8058 7.94572 18.7557L8.90556 16.1269C9.07495 15.6629 9.58836 15.4242 10.0523 15.5935Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.788574 0.539439C0.98466 0.0861487 1.51108 -0.122356 1.96437 0.0737303L5.73669 1.70558C6.22847 1.91831 6.58215 2.36181 6.68013 2.8886L9.00669 15.3973H22.2666L25.2498 6.27325L10.0041 5.52157C9.51086 5.49725 9.13069 5.07765 9.15501 4.58436C9.17933 4.09108 9.59893 3.71091 10.0922 3.73523L25.5718 4.49845C26.6322 4.55073 27.3525 5.59727 27.0225 6.60639L23.9262 16.0764C23.7098 16.7382 23.0925 17.1858 22.3962 17.1858H8.85804C8.08256 17.1858 7.41732 16.6329 7.27552 15.8705L4.93919 3.30927L1.25428 1.71524C0.800992 1.51915 0.592488 0.992729 0.788574 0.539439Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9902 23.6406C20.6137 23.6406 21.1191 23.1351 21.1191 22.5116C21.1191 21.8882 20.6137 21.3827 19.9902 21.3827C19.3667 21.3827 18.8612 21.8882 18.8612 22.5116C18.8612 23.1351 19.3667 23.6406 19.9902 23.6406ZM19.9902 24.9999C21.3644 24.9999 22.4784 23.8858 22.4784 22.5116C22.4784 21.1374 21.3644 20.0234 19.9902 20.0234C18.616 20.0234 17.502 21.1374 17.502 22.5116C17.502 23.8858 18.616 24.9999 19.9902 24.9999Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6142 23.6406C12.2377 23.6406 12.7431 23.1351 12.7431 22.5116C12.7431 21.8882 12.2377 21.3827 11.6142 21.3827C10.9907 21.3827 10.4852 21.8882 10.4852 22.5116C10.4852 23.1351 10.9907 23.6406 11.6142 23.6406ZM11.6142 24.9999C12.9884 24.9999 14.1024 23.8858 14.1024 22.5116C14.1024 21.1374 12.9884 20.0234 11.6142 20.0234C10.24 20.0234 9.12598 21.1374 9.12598 22.5116C9.12598 23.8858 10.24 24.9999 11.6142 24.9999Z" fill="white" />
                        </svg>
                    </span>
                    <span className='text-white text-lg font-family-500 font-medium'>
                        Add Job to Cart
                    </span>
                </p>

            </div>
        </div >
    )
}

export default RecommendedJobs