import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
const CompanyCard = ({
    imagePosition,
    companyTtitle,
    companyLogo,
    companyImage,
    companyDescription
}) => {
    // const navigate = useNavigate()
    const flexCol = imagePosition == "right" ? "flex-col-reverse" : "flex-col"
    return (
        <div className={`flex xl:flex-row lg:flex-row md:flex-row sm:${flexCol} ${flexCol} align-middle xl:mb-20 lg:mb-14 md:mb-10 mb-10 items-center justify-center xl:gap-6 lg:gap-6 md:gap-6 sm:gap-10 gap-10`}>
            {
                imagePosition == "right" ?
                    (
                        <>
                            <div className="card xl:py-8 xl:px-14 lg:py-6 lg:px-6 md:py-6  py-7 px-5 md:px-6 w-full h-max">
                                <div className="flex align-middle xl:mb-6 lg:mb-6 md:mb-5 sm:mb-5 mb-5 items-center justify-start">
                                    <img className='mr-5 h-auto' src={companyLogo} alt="" />
                                    <h3 className='font-medium font-family-500 secondary-heading-color'>
                                        {
                                            companyTtitle
                                        }
                                    </h3>
                                </div>
                                <p className='mb-0 text-xl font-family-500 font-medium xl:text-left lg:text-left md:text-left sm:text-left text-center indent-6 secondary-heading-color'>
                                    {
                                        companyDescription
                                    }
                                </p>
                            </div>
                            <img src={companyImage} className="w-full xl:w-full lg:w-full md:w-1/2 " alt="" />
                        </>
                    )
                    :
                    (
                        <>
                            <img src={companyImage} className="w-full xl:w-full lg:w-full md:w-1/2 " alt="" />
                            <div className="card xl:py-8 xl:px-14 lg:py-6 lg:px-6 md:py-6  py-7 px-5 md:px-6  w-full h-max">
                                <div className="flex align-middle xl:mb-6 lg:mb-6 md:mb-5 sm:mb-5 mb-5 items-center justify-start">
                                    <img className='mr-5 h-auto' src={companyLogo} alt="" />
                                    <h3 className='font-medium font-family-500 secondary-heading-color'>
                                        {
                                            companyTtitle
                                        }
                                    </h3>
                                </div>
                                <p className='mb-0 text-xl font-family-500 font-medium xl:text-left lg:text-left md:text-left sm:text-left text-center indent-6 secondary-heading-color'>
                                    {
                                        companyDescription
                                    }
                                </p>
                            </div>
                        </>
                    )
            }
        </div>
    )
}

export default CompanyCard