import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import EmployeeSignupForm from '../../components/EmployeeSignupForm'


const EmployeeSignup = () => {
    return (
        <>
            <Header bgColor={true} />
            <EmployeeSignupForm />
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default EmployeeSignup