import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
const HomeBanner = () => {
    const navigate = useNavigate()
    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-10 md:pt-16 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-0 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" />
                </div>
                <div className="content flex align-middle xl:w-auto lg:w-auto md:w-11/12 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:text-left lg:text-left md:text-left sm:text-center text-center font-family-700 xl:w-11/12 lg:w-full w-full font-bold xl:mb-8 lg:mb-8 md:mb-8 sm:mb-10 mb-9'>
                        Connecting the world
                        to work
                    </h1>
                    <p className='text-white  xl:text-left lg:text-left md:text-left sm:text-center text-center xl:text-3xl lg:text-xl text-xl xl:mb-20 lg:mb-10 md:mb-10 sm:mb-10 mb-9 font-family-500'>
                        Let us help you realize your career potential
                    </p>
                    <div className="flex xl:flex-row xl:justify-start xl:w-max lg:w-max md:w-max sm:w-full w-full lg:justify-start md:justify-start sm:justify-between justify-between lg:flex-row md:flex-row sm:flex-row flex-col align-middle xl:gap-14 lg:gap-10 md:gap-10 sm:gap-0 gap-7">
                        <a  className='xl:px-14 lg:px-14 md:px-10 sm:px-8 px-11 xl:w-max lg:w-max md:w-max sm:w-max w-max mx-auto flex align-middle justify-center border primary-bg-color rounded-full secondary-border-color xl:py-0 lg:py-0 md:py-2 sm:py-2 py-2 xl:h-14 lg:h-14 md:h-auto sm:h-auto h-auto'>
                            <button className='font-family-500 text-white text-2xl font-semibold'>
                                Find a Job
                            </button>
                        </a>
                        <a  className='xl:px-14 lg:px-14 md:px-10 sm:px-8 px-8 xl:w-max lg:w-max md:w-max sm:w-max w-max mx-auto flex align-middle justify-center border bg-white rounded-full  xl:h-14 lg:h-14 md:h-auto sm:h-auto h-auto xl:py-0 lg:py-0 md:py-2 sm:py-2 py-2' >
                            <button className='font-family-500 secondary-heading-color text-2xl font-semibold'>
                                Find a Talent
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner