import React, { Component, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CandidateProfileSection from './CandidateProfileSection'
import EmployerProfileSection from './EmployerProfileSection'
const ProfileSection = () => {
    const [userRole, setUserRole] = useState()
    const navigate = useNavigate()
    const userData = localStorage.getItem("userInfo")
    const user = JSON.parse(userData)
    useEffect(() => {
        setUserRole(user)
    }, [])
    return (
        <div className="xl:py-20 lg:py-14 md:py-10 sm:py-10 py-10">
            <div className="main-container">
                {
                    userRole?.userRole === "1" ?
                        <CandidateProfileSection />
                        :
                        <EmployerProfileSection />
                }
            </div>
        </div>
    )
}

export default ProfileSection