import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
const LoginBanner = ({ route }) => {
    const navigate = useNavigate()
    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-10 md:pt-16 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-0 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" />
                </div>
                <div className="content flex align-middle xl:w-2/5 lg:w-1/2 md:w-11/12 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:mb-10 lg:mb-10 md:mb-10 sm:mb-10 mb-10 text-center font-family-700 font-extrabold'>
                        Who are you?
                    </h1>
                    <button onClick={() => {
                        navigate(`/candidate-${route}`)
                    }} className='bg-white flex align-middle justify-center items-center mx-auto xl:w-full lg:w-4/5 md:w-4/5 sm:w-full w-full rounded-3xl-5 h-36-5 secondary-heading-color xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl text-2xl  font-family-500 font-normal'>
                        Candidate
                    </button>
                    <span className='my-6 relative border border-white xl:w-full lg:w-4/5 md:w-4/5 mx-auto sm:w-full w-full'>
                        <span className='absolute top-1/2 left-1/2 origin-center text-lg font-family-500 font-normal text-white px-6' style={{ transform: "translate(-50%,-50%)", background: "#69abba" }}>
                            Or
                        </span>
                    </span>
                    <button onClick={() => {
                        navigate(`/employer-${route}`)
                    }} className='bg-white flex align-middle justify-center items-center xl:w-full mx-auto lg:w-4/5 md:w-4/5 sm:w-full w-full rounded-3xl-5 h-36-5 secondary-heading-color xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl text-2xl  font-family-500 font-normal'>
                        Employer
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LoginBanner