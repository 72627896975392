import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CanadaLogo from '../assets/images/canada.png'
import USALogo from '../assets/images/usa.png'
import PakistanLogo from '../assets/images/pakistan.png'
import MacedoniaLogo from '../assets/images/Macedonia.png'
import AlbaniaLogo from '../assets/images/albania.png'
import BosniaLogo from '../assets/images/bosnia.png'
import BrazilLogo from '../assets/images/brazil.png'
const CountryList = () => {
    const navigate = useNavigate()
    return (
        <div className="xl:grid lg:grid md:grid sm:grid flex flex-wrap xl:grid-cols-7 lg:grid-cols-7 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 gap-6 md:grid-cols-5 sm:grid-cols-3 align-middle justify-center items-center">
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={CanadaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Canada</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={USALogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">USA</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={PakistanLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Pakistan</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={BosniaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Macedonia</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={AlbaniaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Albania</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={MacedoniaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Bosnia</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={BrazilLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Brazil</p>
            </div>
        </div >
    )
}

export default CountryList