import React, { Component, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GoogleButton from '../assets/images/google-button.png';
import { RxUpload } from 'react-icons/rx'
const ChangePasswordForm = () => {
    const navigate = useNavigate()

    const [uploadedResume, setUploadedResume] = useState()

    const MobileCode = [
        { value: "+355", label: "+355" },
        { value: "+387", label: "+387" },
        { value: "+55", label: "+55" },
        { value: "+995", label: "+995" },
        { value: "+389", label: "+389" },
        { value: "+92", label: "+92" },
        { value: "+1", label: "+1" },
    ]

    const handleFileInput = (e) => {
        let file = e.target.files[0];
        console.log('file ', file)
        file?.name?.replace(" ", "-");
        setUploadedResume(file);
    };

    return (
        <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
            <h2 className='font-family-700 font-medium text-center primary-heading-color mb-8'>
                Change my Password
            </h2>
            <div className="flex align-middle justify-center flex-col items-center xl:w-1/4 lg:w-1/4 md:w-2/4 xl:px-0 lg:px-0 md:px-0 sm:px-6 px-6 sm:w-full w-full mx-auto">
                <div className="flex mb-6 align-middle justify-between items-center w-full">
                    <input
                        type="password"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        // onChange={(e) => {
                        //     setUsername(e.target.value)
                        // }}
                        id="oldpass"
                        // value={username}
                        placeholder="Old Password"
                    />
                </div>
                <div className="flex mb-6 align-middle justify-between items-center w-full">
                    <input
                        type="password"
                        autoComplete='off'
                        className="form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                        // onChange={(e) => {
                        //     setUsername(e.target.value)
                        // }}
                        id="newpass"
                        // value={username}
                        placeholder="New Password"
                    />
                </div>
                <button className='primary-bg-color w-full mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Save
                </button>
                <a
                    onClick={() => {
                        navigate('/profile')
                    }}
                    className='secondary-heading-color w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                    <span
                        //  onClick={() => {
                        //     navigate('/candidate-login')
                        // }}
                        className='call-to-action-text-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                        Cancle
                    </span>
                </a>
            </div>
        </div >
    )
}

export default ChangePasswordForm